import { WalletTypeEnum } from '@/enums/businessEnum';
import { ChartTypeEnum } from '@/models/kline';
import { history } from '@@/core/history';
import PageEnum from '@/enums/pageEnum';
import { Image } from '@nextui-org/react';
import RenderUtil from '@/utils/RenderUtil';
import FloatValueDisplay from '@/components/display/displayFloatValueDisplay';
import { useModel } from '@@/exports';

export default ({ item, isCollect = false , onClickItem }:  {
  item: any;
  onClickItem?: (item: any) => void;
  isCollect?: boolean;
}) => {
  const { isMobile } = useModel('system');
  const { setCoinType } = useModel('kline');
  const { setLocalCoin } = useModel('coin');
  const { getSocketRowByName } = useModel('socket');
  const soketData = getSocketRowByName(item.symbol);
  const { exchangeSetConvert } = useModel('exchange');

  return <div
    key={item.name}
    className={`flex pb-5 items-center justify-between w-full  cursor-pointer`}
    onClick={() => {
      setCoinType(ChartTypeEnum.COIN);
      if (isCollect) {
        setLocalCoin({
          ...item,
          id: item?.sourceId,
        });
      } else {
        setLocalCoin(item);
      }
      if (!isMobile && location.pathname === PageEnum.HOME) {
        history.push(
          PageEnum.HOME + '?type=' + WalletTypeEnum.COIN,
        );
        //便于扩展
        onClickItem?.(item);
      }
      history.replace(PageEnum.TRANSACTION);
      //便于扩展
      onClickItem?.(item);
    }}
  >
    {/* 第一列 */}
    <div className="flex-2  font-bold  flex justify-start text-sm items-center">
      <div className="w-[30px]">
        <div className="w-[18px] h-[18px] relative mr-3">
          <Image
            classNames={{
              wrapper: '!max-w-[100%]',
            }}
            className="flex-shrink-0  object-cover  absolute left-[0] top-[-3px] z-[1]"
            src={item?.icon1}
          />
          <Image
            classNames={{
              wrapper: '!max-w-[100%]',
            }}
            className="!w-[18px] h-[18px]  flex-shrink-0 rounded-full object-cover absolute left-[10px] top-0 z-[2]"
            src={item?.icon2}
          />
        </div>
      </div>

      <div className="truncate">{item?.company}</div>
      <div className="flex pb-1 h-fit items-end  text-foreground break-words text-base ">
        <span className="h-[20px]"> {(item.alias ?? item.symbolName ?? item.symbol)?.slice(0, -4)}</span>
        <span
          className="flex items-end leading-[12px] ml-1 h-[20px] text-xs font-normal text-auxiliaryTextColor">/ {(item.alias ?? item.symbolName ?? item.symbol)?.slice(-4)}</span>
      </div>

    </div>

    {/* 第二列 */}
    <div className="flex-1 text-right mr-2">
      <div className="text-sm font-bold">
        {RenderUtil.FormatAmount(
          soketData?.a,
          item?.priceAccuracy,
          true,
        )}
      </div>

      <div
        className="text-[11px] mt-[2px] text-iconFontColor"
        style={{ whiteSpace: 'nowrap' }}
      >
        {exchangeSetConvert({
          price: soketData?.a,
          isJoin: true,
          showMask: false,
        })}
      </div>
    </div>
    {/* 第三列 */}
    <div className="flex-1 text-right">
      <FloatValueDisplay
        value={soketData.pe}
        unit={soketData.pe > 0 ? '+' : ''}
        ratio
        len={2}
        fontSize={13}
      />
    </div>
  </div>;
}