import { MingcuteDownLine } from '@/assets/icons/comm/MingcuteDownLine';
import { cn } from '@/utils';
import React from 'react';

interface SortColumnProps {
  id: number;
  label: string;
  className?: string;
  onSort: (id: number, sort: 'asc' | 'desc' | null) => void;
  active: boolean;
  sortOrder: 'asc' | 'desc' | null;
}

const SortColumn: React.FC<SortColumnProps> = ({
  id,
  label,
  onSort,
  className,
  active,
  sortOrder,
}) => {
  const handleSort = () => {
    // 根据当前的排序状态进行切换，第三次点击时取消排序
    let newSortOrder: 'asc' | 'desc' | null;
    if (sortOrder === 'asc') {
      newSortOrder = 'desc';
    } else if (sortOrder === 'desc') {
      newSortOrder = null; // 第三次点击取消排序
    } else {
      newSortOrder = 'asc';
    }
    onSort(id, newSortOrder); // 调用父级方法传递当前排序
  };

  return (
    <div
      className={cn(
        'text-iconFontColor my-2 flex auxiliaryText text-xs cursor-pointer justify-start',
        className ?? '',
      )}
      onClick={handleSort} // 点击触发排序
    >
      {/* 第一列 */}
      <div className="">{label}</div>

      {/* 排序图标 */}
      <div className="flex justify-center items-center flex-col ml-2 relative bg-alipayColor">
        {/* 升序箭头 */}
        <MingcuteDownLine
          className={`text-[#86909C] absolute -bottom-1 mb-[2px] ${
            active && sortOrder === 'asc' && '!text-backContrastColor'
          }`}
        />
        {/* 降序箭头 */}
        <MingcuteDownLine
          className={`rotate-180 text-[#86909C] absolute -top-1 mt-[2px] ${
            active && sortOrder === 'desc' && '!text-backContrastColor'
          }`}
        />
      </div>
    </div>
  );
};

export default SortColumn;
