import BasePullList from '@/components/base/basePullList/index';
import SortColumn from '@/components/features/featuresMarketList/SortColumn';
import RangeSelector from '@/components/features/featuresRangeSelector';
import { GetCoinListTypeEnum } from '@/enums/businessEnum';
import CoinCard from '@/pages/public/market/components/CoinCard';
import { getTradingSymbolsApi } from '@/services/api/requestApi';
import { useModel } from '@@/exports';
import { useMount } from 'ahooks';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default ({
  fuzzy,
  mode = 'complex',
  defaultList = [],
  isHomeSort = false,
  setLoadingDisplay,
  setChoiceCoinSelected,
  choiceCoinSelected,
}: {
  fuzzy?: string;
  mode?: 'complex' | 'simple';
  defaultList?: any;
  isHomeSort?: boolean;
  choiceCoinSelected?: string;
  setLoadingDisplay?: (val: boolean) => void;
  setChoiceCoinSelected?: (val: string) => void;
}) => {
  // 基础定义
  const { t } = useTranslation();
  const { sendMsg, isSub } = useModel('socket');
  const {
    sortState,
    setSortState,
    setIsHomeSort,
    coinSelected,
    setcoinSelected,
    setSortType,
  } = useModel('system');


  const [pageParams, setPageParams] = useState({
    pageNo: 1,
    pageSize: 10,
  });

  // 自动监听参数自动请求
  const params = useMemo(() => {
    return {
      ...pageParams,
      plate: coinSelected,
      type: GetCoinListTypeEnum.COIN,
      ...sortState,
      fuzzy,
    };
  }, [pageParams, coinSelected, sortState, fuzzy]);
  // 获取列表

  useEffect(() => {
    if (defaultList?.length) {
      // 订阅当前 加载的股票
      sendMsg(
        `{"type":"SUB","data": ${JSON.stringify(
          defaultList?.map?.((i: any) => i.symbol),
        )}}`,
      );
    }
  }, [isSub, defaultList]);
  // 排序
  const onSort = (id: number, sortOrder: 'asc' | 'desc' | null) => {
    let newSortState = { ...sortState };
    setIsHomeSort(mode === 'simple');

    // 重置其他字段的排序为 0，并只更新当前点击的字段
    switch (id) {
      case 2: // 价格
        newSortState = {
          priceSort: sortOrder === 'asc' ? 1 : sortOrder === 'desc' ? -1 : 0, // asc:1, desc:-1, null:0
          upDownSort: 0, // 保留其他字段的排序状态
          upDownRangSort: 0,
        };
        break;

      case 3: // 涨跌幅
        newSortState = {
          priceSort: 0, // 保留其他字段的排序状态
          upDownSort: 0,
          upDownRangSort:
            sortOrder === 'asc' ? 1 : sortOrder === 'desc' ? -1 : 0, // asc:1, desc:-1, null:0
        };
        break;

      case 1: // 名称
        newSortState = {
          priceSort: 0, // 保留其他字段的排序状态
          upDownSort: sortOrder === 'asc' ? 1 : sortOrder === 'desc' ? -1 : 0, // asc:1, desc:-1, null:0
          upDownRangSort: 0,
        };
        break;
      default:
        break;
    }
    setSortType(GetCoinListTypeEnum.COIN);
    // 更新排序状态
    setSortState(newSortState);
  };

  return (
    <div>
      <div className=" justify-between  mt-3">
        <div className="flex">
          <RangeSelector
            value={mode === 'simple'?choiceCoinSelected:coinSelected}
            onChange={(val:string)=>{
              setcoinSelected(val);
              // 
              if(window.location.pathname==="/home"){
                setChoiceCoinSelected?.(val);
              }
            }}
            options={[
              { text: t('全部'), value: '' },
              { text: t('Hot'), value: '1' },
              { text: t('Layer2'), value: '2' },
              { text: t('AI'), value: '3' },
              { text: t('Metaverse'), value: '4' },
              { text: t('GameFi'), value: '5' },
            ]}
          />
        </div>
        <div className="text-iconFontColor my-2 flex justify-between auxiliaryText text-xs pr-[5px]">
          <div className="flex-2">
            <SortColumn
              id={1}
              label={t('名称')}
              onSort={onSort}
              active={sortState.upDownSort !== null} // 如果不为 0，则表示激活状态
              sortOrder={
                sortState.upDownSort === 1
                  ? 'asc'
                  : sortState.upDownSort === -1
                  ? 'desc'
                  : null
              } // 根据状态显示升序、降序或未排序
            />
          </div>

          <div className="flex-1 pr-2">
            <SortColumn
              id={2}
              onSort={onSort}
              label={t('价格')}
              className="justify-end"
              active={sortState.priceSort !== null}
              sortOrder={
                sortState.priceSort === 1
                  ? 'asc'
                  : sortState.priceSort === -1
                  ? 'desc'
                  : null
              }
            />
          </div>
          <div className="flex-1 !min-w-fit">
            <SortColumn
              id={3}
              onSort={onSort}
              label={t('涨幅比')}
              className="justify-end"
              active={sortState.upDownRangSort !== null}
              sortOrder={
                sortState.upDownRangSort === 1
                  ? 'asc'
                  : sortState.upDownRangSort === -1
                  ? 'desc'
                  : null
              }
            />
          </div>
        </div>
        <div className="min-h-[40vh]">
          {/*分页模式*/}
          {/* {mode === 'complex' && <PullToRefresh
            loadMore={loadMore}
            total={total}
            list={list}
            error={error}
            loading={loading}
            {...resetPageProps}
          >
            {list?.map(item => {
              return <CoinCard key={item?.id} item={item} />;
            })}
          </PullToRefresh>} */}
          {/*分页模式*/}
          {mode === 'complex' && (
            <BasePullList
              fetchFunction={getTradingSymbolsApi} // 请求数据函数
              params={params} // 请求参数
              setParams={setPageParams}
              loadingDisplay={false}
              setLoadingDisplay={setLoadingDisplay}
              renderItem={(item) => {
                return (
                  <div className="">
                    <CoinCard key={item?.id} item={item} />
                  </div>
                );
              }} // 渲染列表项
            />
          )}

          {/*外部传参列表，分页模式*/}
          {mode === 'simple' &&
            defaultList?.map?.((item: any) => {
              return <CoinCard key={item?.id} item={item} />;
            })}
        </div>
      </div>
    </div>
  );
};
