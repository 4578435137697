import FloatValueDisplay from '@/components/display/displayFloatValueDisplay';
import { WalletTypeEnum } from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import { ChartTypeEnum } from '@/models/kline';
import RenderUtil from '@/utils/RenderUtil';
import { history } from '@@/core/history';
import { useModel } from '@@/exports';
import { Image } from '@nextui-org/react';

export default ({ item, onClickItem, isCollect = false }: {
  item: any;
  onClickItem?: (item: any) => void;
  isCollect?: boolean;
}) => {
  const { isMobile } = useModel('system');
  const { setCoinType } = useModel('kline');
  const { setLocalContract, updateContract } = useModel('contract');
  const { getSocketRowByName } = useModel('socket');
  const soketData = getSocketRowByName(item.symbol);
  const {  exchangeSetConvert } = useModel('exchange');

  return (
    <div
      key={item.name}
      className={`flex pb-5 items-center justify-between w-full  cursor-pointer`}
      onClick={() => {
        setCoinType(ChartTypeEnum.CONTRACT);
        if (isCollect) {
          setLocalContract({
            ...item,
            id: item?.sourceId,
          });
        } else {
          setLocalContract(item);
        }
        if (!isMobile && location.pathname === PageEnum.HOME) {
          history.push(PageEnum.HOME + '?type=' + WalletTypeEnum.CONTRACT);
          //便于扩展
          onClickItem?.(item);
        }
        history.replace(PageEnum.CONTRACT);
        //便于扩展
        onClickItem?.(item);
        // 更新缓存
        updateContract(item?.symbol);
      }}
    >
      {/* 第一列 */}
      <div className="flex-2  font-bold  flex justify-start text-sm items-center">
        <div className="w-[30px]">
          <Image
            className="w-[26px] h-[26px] mr-2 flex-shrink-0 rounded-full object-cover"
            src={item?.icon1}
          />
        </div>
        <div className="truncate">{item?.company}</div>
        <div className="flex pb-1 h-fit items-end  text-foreground break-words text-base ">
          <span className="h-[20px]"> {(item.alias ?? item.symbolName ?? item.symbol)?.slice(0,-4)}</span>
          <span className="flex items-end leading-[12px] ml-1 h-[20px] text-xs font-normal text-auxiliaryTextColor">/ {(item.alias ?? item.symbolName ?? item.symbol)?.slice(-4)}</span>
        </div>
      </div>

      {/* 第二列 */}
      <div className="flex-1 text-right mr-2">
        <div className="text-sm font-bold">
          {RenderUtil.FormatAmount(soketData?.a, item?.priceAccuracy, true)}
        </div>

        <div
          className="text-[11px] mt-[2px] text-iconFontColor"
          style={{ whiteSpace: 'nowrap' }}
        >
          {exchangeSetConvert({
            price: soketData?.a,
            isJoin: true,
            showMask: false,
          })}
        </div>
      </div>
      {/* 第三列 */}
      <div className="flex-1 text-right">
        <FloatValueDisplay
          value={soketData.pe}
          unit={soketData.pe > 0 ? '+' : ''}
          ratio
          len={2}
          fontSize={13}
        />
      </div>
    </div>
  );
};
