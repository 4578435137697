import BasePullList from '@/components/base/basePullList/index';
import SortColumn from '@/components/features/featuresMarketList/SortColumn';
import {
  ContractListTypeEnum,
  GetCoinListTypeEnum,
} from '@/enums/businessEnum';
import ContractCard from '@/pages/public/market/components/ContractCard';
import { getTradingSymbolsApi } from '@/services/api/requestApi';
import { useModel } from '@@/exports';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default ({
  fuzzy,
  mode = 'complex',
  defaultList = [],
  isHomeSort = false,
  setLoadingDisplay,
}: {
  fuzzy?: string;
  mode?: 'complex' | 'simple';
  defaultList?: any;
  isHomeSort?: boolean;
  setLoadingDisplay?: (val: boolean) => void;
}) => {
  // 基础定义
  const { t } = useTranslation();
  const { sendMsg, isSub } = useModel('socket');
  const { sortState, setSortState, setIsHomeSort, setSortType } =
    useModel('system');
  const [coinSelected] = useState('');
  const [pageParams, setPageParams] = useState({
    pageNo: 1,
    pageSize: 10,
  });

  // 自动监听参数自动请求
  const params = useMemo(() => {
    return {
      ...pageParams,
      plate: coinSelected,
      contractType: ContractListTypeEnum.CRYPTOCURRENCY,
      type: GetCoinListTypeEnum.CONTRACT,
      ...sortState,
      fuzzy,
    };
  }, [pageParams, coinSelected, sortState, fuzzy]);

  useEffect(() => {
    if (defaultList?.length) {
      // 订阅当前 加载的股票
      sendMsg(
        `{"type":"SUB","data": ${JSON.stringify(
          defaultList?.map?.((i: any) => i.symbol),
        )}}`,
      );
    }
  }, [isSub, defaultList]);

  // 排序
  const onSort = (id: number, sortOrder: 'asc' | 'desc' | null) => {
    let newSortState = { ...sortState };
    setIsHomeSort(mode === 'simple');
    // 重置其他字段的排序为 0，并只更新当前点击的字段
    switch (id) {
      case 2: // 价格
        newSortState = {
          priceSort: sortOrder === 'asc' ? 1 : sortOrder === 'desc' ? -1 : 0, // asc:1, desc:-1, null:0
          upDownSort: 0, // 保留其他字段的排序状态
          upDownRangSort: 0,
        };
        break;

      case 3: // 涨跌幅
        newSortState = {
          priceSort: 0, // 保留其他字段的排序状态
          upDownSort: 0,
          upDownRangSort:
            sortOrder === 'asc' ? 1 : sortOrder === 'desc' ? -1 : 0, // asc:1, desc:-1, null:0
        };
        break;

      case 1: // 名称
        newSortState = {
          priceSort: 0, // 保留其他字段的排序状态
          upDownSort: sortOrder === 'asc' ? 1 : sortOrder === 'desc' ? -1 : 0, // asc:1, desc:-1, null:0
          upDownRangSort: 0,
        };
        break;
      default:
        break;
    }
    setSortType(GetCoinListTypeEnum.CONTRACT);
    // 更新排序状态
    setSortState(newSortState);
  };

  return (
    <div>
      <div className=" justify-between  mt-1">
        <div className="text-iconFontColor my-2 flex justify-between auxiliaryText text-xs pr-[5px]">
          <div className="flex-2">
            <SortColumn
              id={1}
              label={t('名称')}
              onSort={onSort}
              active={sortState.upDownSort !== null} // 如果不为 0，则表示激活状态
              sortOrder={
                sortState.upDownSort === 1
                  ? 'asc'
                  : sortState.upDownSort === -1
                  ? 'desc'
                  : null
              } // 根据状态显示升序、降序或未排序
            />
          </div>

          <div className="flex-1 pr-2">
            <SortColumn
              id={2}
              onSort={onSort}
              label={t('价格')}
              className="justify-end"
              active={sortState.priceSort !== null}
              sortOrder={
                sortState.priceSort === 1
                  ? 'asc'
                  : sortState.priceSort === -1
                  ? 'desc'
                  : null
              }
            />
          </div>
          <div className="flex-1 !min-w-fit">
            <SortColumn
              id={3}
              onSort={onSort}
              label={t('涨幅比')}
              className="justify-end"
              active={sortState.upDownRangSort !== null}
              sortOrder={
                sortState.upDownRangSort === 1
                  ? 'asc'
                  : sortState.upDownRangSort === -1
                  ? 'desc'
                  : null
              }
            />
          </div>
        </div>
        <div className="min-h-[40vh]">
          {/*分页模式*/}
          {mode === 'complex' && (
            <BasePullList
              fetchFunction={getTradingSymbolsApi} // 请求数据函数
              params={params} // 请求参数
              setParams={setPageParams}
              loadingDisplay={false} // 取消加载图片
              setLoadingDisplay={setLoadingDisplay} // 修改外部加载状态
              renderItem={(item: any) => {
                return (
                  <div className="">
                    <ContractCard key={item?.id} item={item} />
                  </div>
                );
              }} // 渲染列表项
            />
          )}

          {/*外部传参列表，分页模式*/}
          {mode === 'simple' &&
            defaultList?.map?.((item: any) => {
              return <ContractCard key={item?.id} item={item} />;
            })}
        </div>
      </div>
    </div>
  );
};
