import { FluentTriangle16Filled } from '@/assets/icons/comm/FluentTriangle16Filled';
import RenderUtil from '@/utils/RenderUtil';
import { useModel } from '@umijs/max';
import { usePrevious } from 'ahooks';
import { CSSProperties, FC, useMemo } from 'react';

interface ValueDisplayProps {
  value: string | number;
  className?: string;
  style?: CSSProperties;
  len?: number; //格式化长度
  ratio?: boolean; //百分比
  isSubLen?: boolean; //是否裁剪长度
  propsColor?: string;
  type?: 'card' | 'text';
  unit?: string;
  fontSize?: string | number;
}

const ValueDisplay: FC<ValueDisplayProps> = ({
  value,
  style = {},
  className = '',
  len,
  ratio,
  propsColor,
  isSubLen = true,
  type = 'card',
  unit,
  fontSize = 15,
}) => {
  const oldValue = usePrevious(value) ?? '0';

  const comStyle = {
    textAlign: 'center',
    borderRadius: '5px',
    fontSize: fontSize,
    width: '75px',
    marginLeft: 'auto',
    minHeight: 30,
    wordBreak: 'break-word',
  };

  // 红涨绿跌设置
  const { upDownColorConfig } = useModel('system');
  // 当type为卡片的时候
  let cardColor: any = {
    ...comStyle,
  }; // 默认颜色
  // 当type为文字的时候
  let textColor: any = {};

  const displayValue = value !== '0' ? value : oldValue;
  // if (Number(value) > Number(oldValue)) {

  if (Number(value) > 0) {
    // 涨
    cardColor = {
      color: '#fff',
      ...comStyle,
      background: upDownColorConfig === 'upGreen' ? '#2db880' : '#ee4456',
    };
    textColor = {
      color: upDownColorConfig === 'upGreen' ? '#2db880' : '#ee4456',
    };
  } else {
    //跌
    cardColor = {
      color: '#fff',
      ...comStyle,
      background: upDownColorConfig === 'upGreen' ? '#ee4456' : '#2db880',
    };
    textColor = {
      color: upDownColorConfig === 'upGreen' ? '#ee4456' : '#2db880',
    };
  }

  const combinedStyle = {
    ...cardColor,
    ...style,
    // overflow: 'hidden',
    // textOverflow: 'ellipsis', //文本溢出显示省略号
    // whiteSpace: 'nowrap', //文本不会换行
    // display: 'inline-block',
  };

  const valueMemo = useMemo(() => {
    if (displayValue) {
      if (len || len === 0) {
        return RenderUtil.FormatAmount(displayValue, len);
      } else {
        return RenderUtil.FormatAmount(displayValue);
      }
    }
    return <span className="color_secondary text-sm">0</span>;
  }, [displayValue, len]);

  if (!isSubLen)
    return (
      <span style={combinedStyle} className={className}>
        {displayValue} {ratio ? '%' : ''}
      </span>
    );

  return (
    <>
      {type === 'card' && (
        <div
          style={
            propsColor ? { ...combinedStyle, color: propsColor } : combinedStyle
          }
          className={`py-[1px] px-[2px] flex justify-center items-center bg-backgroundAuxiliaryColor ${className}`}
        >
          {unit}
          {valueMemo} {ratio ? '%' : ''}
        </div>
      )}
      {type === 'text' && (
        <div
          style={{ ...textColor }}
          className="flex flex-col items-center justify-center h-full"
        >
          <FluentTriangle16Filled
            width="10px"
            height="10px"
            style={{
              visibility:
                Number(value) > Number(oldValue) ? 'visible' : 'hidden',
            }}
          />

          <span>
            {unit}
            {displayValue} {ratio ? '%' : ''}
          </span>
        </div>
      )}
    </>
  );
};

export default ValueDisplay;
